@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

/***
 Mobile-first redesign, targets screens smaller than 600px wide (like a phone)
 ***/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--primary-color: #302ae6;
	--secondary-color: #536390;
	--font-color: #232323;
	--bg-color: #efefef;
	--heading-color: #292922;
}

[data-theme="dark"] {
	--primary-color: #9a97f3;
	--secondary-color: #818cab;
	--font-color: #e1e1ff;
	--bg-color: #161625;
	--heading-color: #292922;
}

body {
	background-color: var(--bg-color);
	color: var(--font-color);
	font-family: 'Roboto', sans-serif;
	font-size: 1.1rem;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.wrapper {
	width: 100%;
	margin: 0;
	padding: 0 1rem;
}

.header {
	/* background-color: #eaeaea; */
	background-color: var(--heading-color);
	color: var(--bg-color);
	border-bottom: solid 1px #cccccc;
	text-align: center;

	.avatar {
		display: block;
		margin: 0 auto;
		padding: 1rem 0;
		height: 8rem;
	}

	a {
		color: var(--secondary-color);
	}

	nav {
		padding: 1rem 0;

		a + a:before {
			content: ' | ';
		}
	}
}

.content {
	padding: 1em 0;
	text-align: left;

	h1 {
		margin: 1rem 0 2rem 0;
		font-family: 'Poppins', sans-serif;
		font-weight: normal;
		font-size: 2rem;
		text-align: center;
	}

	h2 {
		margin: 2rem 0;
		font-family: 'Noto Serif', serif;
		font-weight: normal;
		font-size: 1.5rem;
		text-align: left;
	}

	a {
		color: var(--primary-color);
		text-decoration: underline;
	}

	a:visited {
		color: var(--secondary-color);
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.5em;
	}

	ul {
		margin-left: 2em;
		margin-bottom: 2em;

		li + li {
			margin-top: 0.5rem;
		}
	}

	.post-list {
		margin-left: 1rem;
		list-style: none;

		li {
			margin: 1rem 0;
		}
	}

	.post-date {
		font-size: 0.9em;
		color: var(--secondary-color);
	}
}

.footer {
	padding: 1.5rem 0;
	text-align: center;

	a {
		color: var(--secondary-color);
		font-weight: bold;
	}

	h6 {
		margin: 0.5rem 0;
		font-size: 1rem;
		font-weight: normal;
	}
}

.posts {
	h2 {
		font-family: 'Roboto', sans-serif;
		font-size: 1.2rem;

		a {
			text-decoration: none;
		}
	}
}

.post-heading {
	margin: 0 0 2rem 0;

	h1 {
		margin: 0.5em 0;
		line-height: 1.25em;
	}

	h4 {
		font-family: 'Roboto', sans-serif;
		font-size: 1rem;
		text-align: center;

		a {
			color: var(--secondary-color);
			text-decoration: none;
		}
	}

	h5 {
		font-family: 'Noto Serif', serif;
		font-size: 1rem;
		font-style: italic;
		color: var(--secondary-color);
		text-align: center;
	}
}

.post-body {
	margin: 1.5rem 0;

	h2, h3 {
		font-family: 'Noto Serif', serif;
		margin: 1.2rem 0;
	}

	h2 {
		color: #dc381f;
		font-size: 1.5rem;
	}

	h3 {
		color: #af4433;
		font-size: 1.2rem;
		font-style: italic;
	}

	code.language-plaintext {
		background: #dadada;
		border-bottom: solid 1px #aaa;
	}

	figure.highlight {
		margin: 1.2rem 0;

		pre {
			padding: 8px;
		}
	}

	img {
		display: block;
		margin: 1.5em auto;
		max-width: 100%;
	}

	img + em {
		display: block;
		font-size: 1rem;
		text-align: center;
		margin-top: -1.25em;
	}

	ul {
		margin-left: 2em;
		margin-bottom: 1em;

		li {
			line-height: 1.1em;
		}

		li + li {
			margin-top: .5em;
		}
	}
}

.resume {
	h1 {
		font-family: 'Roboto', sans-serif;
		color: var(--font-color);
		margin: 0;
		padding: 0;
		font-size: 2em;
		text-align: center;
		text-transform: uppercase;
	}

	h2 {
		color: var(--font-color);
		margin: 0;
		padding: 0;
		font-size: 1.3em;
		text-align: center;
	}

	h3 {
		font-family: 'Noto Serif', serif;
		font-style: italic;
		color: #dc381f;
		margin: 1.5rem 0 0.5rem 0;
	}

	h4 {
		font-size: 0.9em;
		text-align: center;
		color: var(--secondary-color);
		margin: 1rem 0;
		padding: 0;

		a {
			color: var(--secondary-color);
		}
	}

	hr {
		width: 50%;
		margin: 1.5rem auto;
		color: #454545;
	}

	p {
		line-height: 1.45em;
	}

	table {
		margin: 1rem 0;

		tr {
			background-color: #23232323;

			td {
				padding: 0.5rem;
			}
		}
	}

	ul {
		margin-left: 1.5rem;
	}
}

@media (min-width: 600px) {
	.wrapper {
		max-width: 960px;
		margin: 0 auto;
		padding: 0 2em;
	}

	.header {
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 1;

		.wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;

			strong {
				display: flex;
				align-items: center;
			}
		}

		.avatar {
			display: inline-block;
			height: 5rem;
			margin-right: 0.5em;
		}
	}

	.footer {
		position: relative;
		top: 8rem;
	}

	.content {
		position: relative;
		top: 8rem;

		h1 {
			font-size: 3rem;
		}

		.post-list {
			margin-left: 5rem;
		}
	}

	.post-body {
		p {
			line-height: 1.5em;
		}
	}

	.resume {
		h1 {
			font-size: 2.5em;
			text-align: left;
		}
	
		h2 {
			font-size: 2em;
			text-align: left;
		}
	
		p {
			margin-left: 3rem;
			line-height: 1.5em;
		}
	
		table {
			margin-left: 3rem;
		}
	
		ul {
			margin-left: 5rem;
		}

		a {
			color: var(--primary-color);
		}

		a:visited {
			color: var(--secondary-color);
		}
	}
}

.theme-switch-wrapper {
	position: absolute;
	right: 2rem;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: center;

	em {
		color: var(--secondary-color);
		margin-left: 10px;
		font-size: 1rem;
	}
}

.theme-switch {
	display: inline-block;
	position: relative;
	height: 34px;
	width: 60px;

	input {
		display: none;
	}
}

.slider {
	background-color: #ccc;
	position: absolute;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	cursor: pointer;
	transition: .4s;
}

.slider:before {
	background-color: #fff;
	content: "";
	position: absolute;
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	transition: .4s;
}

input:checked + .slider {
	background-color: #66bb6a;
}

input:checked + .slider:before {
	transform: translateX(26px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}